import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class SEOService {
  constructor(private title: Title, private meta: Meta, @Inject(DOCUMENT) private document,) { }

  updateTitle(title: string) {
    if (title)
      this.title.setTitle(title);
  }

  updateOgTitle(title: string) {
    this.meta.updateTag({ name: 'og:title', content: title })
    this.meta.updateTag({ name: 'author', content: 'Vj Ragss | VjRagss' });
  }

  updateOgType(type: string) {
    this.meta.updateTag({ name: 'og:type', content: type })
  }

  updateOgUrl(url: string) {
    console.log('calledddd', url)
    this.meta.updateTag({ name: 'og:url', content: url })
  }

  updateOgImage(url: string) {
    this.meta.updateTag({ name: 'og:image', content: url })
    this.meta.updateTag({ name: 'og:image-alt', content: url })
  }

  updateOgSiteName(siteName: string) {
    this.meta.updateTag({ name: 'og:site_name', content: siteName })
    this.meta.updateTag({ name: 'site_name', content: siteName })
  }

  updateOgDescription(desc: string) {
    this.meta.updateTag({ name: 'og:description', content: desc })
    this.meta.updateTag({ name: 'description', content: desc });
  }

  UpdateKeywords(keyword: string) {
    this.meta.updateTag({ name: 'keywords', content: keyword });
  }

  setCanonicalURL(url?: string) {
    const canonicalURL = url === undefined ? this.document.location.origin : this.document.location.origin + '/' + url;
    let link: HTMLLinkElement = this.document.createElement('link');

    link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(link);
    link.setAttribute('href', canonicalURL);
  }
}
