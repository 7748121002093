import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SEOService } from './services/seo.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent {
    title = 'AsicZ';

    constructor(
        private titleService: Title,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private seoService: SEOService,
    ) {
        let local = localStorage.getItem('cart-items');
        if (local) {
            localStorage.setItem('cart-items', local);
        }
    }

    setDocTitle(title: string) {
        this.titleService.setTitle(title);
    }

    ngOnInit() {
        const appTitle = this.titleService.getTitle();
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) route = route.firstChild;
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.data)
            )
            .subscribe((event: any) => {
                this.seoService.updateTitle(event.title || this.title);

                if (event.ogurl) {
                    this.seoService.updateOgUrl(event.ogurl);
                }

                if (event.title) {
                    this.seoService.updateOgTitle(event.title || this.title);
                }

                if (event.description) {
                    this.seoService.updateOgDescription(event.description);
                }

                if (event.ogimg) {
                    this.seoService.updateOgImage(event.ogimg);
                }

                if (event.site_name) {
                    this.seoService.updateOgSiteName(event.site_name);
                }

                if (event.keywords) {
                    this.seoService.UpdateKeywords(event.keywords);
                }

                if (event.canonicalUrl) {
                    this.seoService.setCanonicalURL(event.canonicalUrl);
                }
            });
    }
}
