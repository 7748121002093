import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgZorroAntdModule } from './nz-zorro/ng-zorro-antd.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { HighchartsChartModule } from 'highcharts-angular';
import { FeatherIconsModule } from './feather-icons/feather-icons.module';
import { Ng2TelInputModule } from 'ng2-tel-input';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { GearsComponent } from './components/gears/gears.component';
import { PasswordComponent } from './components/password/password.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthFooterComponent } from './auth-footer/auth-footer.component';
import { AuthHeaderComponent } from './auth-header/auth-header.component';
import { TeamMembersComponent } from './components/team-members/team-members.component';
import { DotdComponent } from './dotd/dotd.component';
import { AddressComponent } from './components/address/address.component';
import { ClipboardModule } from 'ngx-clipboard';
import { QRCodeModule } from 'angular2-qrcode';
import { VideoComponent } from './components/video/video.component';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        GearsComponent,
        PageNotFoundComponent,
        PasswordComponent,
        AuthFooterComponent,
        AuthHeaderComponent,
        TeamMembersComponent,
        DotdComponent,
        AddressComponent,
        VideoComponent,
    ],
    imports: [
        CommonModule,
        NgZorroAntdModule,
        FormsModule,
        ClipboardModule,
        ReactiveFormsModule,
        RouterModule,
        NgxPageScrollCoreModule.forRoot({ duration: 450 }),
        NgxPageScrollModule,
        HighchartsChartModule,
        FeatherIconsModule,
        Ng2TelInputModule,
        QRCodeModule
    ],
    exports: [
        NgZorroAntdModule,
        FormsModule,
        RouterModule,
        NgxPageScrollCoreModule,
        NgxPageScrollModule,
        ReactiveFormsModule,
        HighchartsChartModule,
        FeatherIconsModule,
        Ng2TelInputModule,
        HeaderComponent,
        FooterComponent,
        GearsComponent,
        DotdComponent,
        PageNotFoundComponent,
        PasswordComponent,
        AuthFooterComponent,
        AuthHeaderComponent,
        TeamMembersComponent,
        AddressComponent,
        ClipboardModule,
        QRCodeModule,
        VideoComponent
    ],
})
export class SharedModule {}
